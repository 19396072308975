import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/utils';
import { AuthenticationProvider } from '@onemedical/auth';
import createAuthenticatedHttpLink from '@onemedical/graphql-auth';
import theme from '@onemedical/react-theme';
import { render } from 'react-dom';

import App from './app/App';
import * as serviceWorker from './serviceWorker';

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      // silence "Cache data may be lost when replacing the roles field of a
      // InternalUser object." development-time warning triggered by the
      // UpdateProfile mutation
      InternalUser: {
        fields: {
          roles: {
            merge(_, incoming: []) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: createAuthenticatedHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL }),
});

// namespace mui component css
ClassNameGenerator.configure((componentName) => `onelife-container-${componentName}`);

render(
  <AuthenticationProvider
    providerUrl={process.env.REACT_APP_AUTH_PROVIDER_URL!}
    clientID={process.env.REACT_APP_AUTH_CLIENT_ID!}
    audience="https://onemedical.com"
    scope="openid profile email"
    callbackPath="/oauth"
    auth0Connection={process.env.REACT_APP_AUTH0_CONNECTION}
    auth0LogoutReturnTo={`${process.env.REACT_APP_ONELIFE_URL}/auth/logout`}
  >
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </AuthenticationProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
