import mixpanel from 'mixpanel-browser';

const enabled = !!process.env.REACT_APP_MIXPANEL_TOKEN;

if (enabled) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!);

export default {
  identify: (id: string) => {
    if (enabled) mixpanel.identify(id);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (name: string, props: any) => {
    if (enabled) mixpanel.track(name, { application: 'onelife-container', ...props });
  },
};
