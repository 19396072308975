import { alpha, Box, styled } from '@mui/material';
import Highlighter from 'react-highlight-words';

import { PatientAutocompleteOption } from './NavBar.types';
import { viewAllResults } from './NavBar.utils';

const classes = {
  searchName: 'searchName',
  searchDescription: 'searchDescription',
  searchHighlightName: 'searchHighlightName',
  searchHighlightDescription: 'searchHighlightDescription',
};

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  [`& .${classes.searchName}`]: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.searchDescription}`]: {
    fontSize: theme.typography.body2.fontSize,
  },

  [`& .${classes.searchHighlightName}`]: {
    backgroundColor: alpha(theme.palette.info.light, 0.1),
    color: theme.palette.info.dark,
  },

  [`& .${classes.searchHighlightDescription}`]: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.light,
  },
}));

const PatientSearchResult = ({
  option,
  searchQuery,
}: {
  option: PatientAutocompleteOption;
  searchQuery: string;
}) => {
  if (option.title === viewAllResults) {
    return (
      <Root>
        <Box color="primary.main" fontWeight="bold">
          {viewAllResults}
        </Box>
      </Root>
    );
  }
  return (
    <Root data-cy="patient-search-row">
      <Highlighter
        className={classes.searchName}
        highlightClassName={classes.searchHighlightName}
        searchWords={[searchQuery]}
        autoEscape
        textToHighlight={option.title}
      />
      {option.description && (
        <div>
          <Highlighter
            className={classes.searchDescription}
            highlightClassName={classes.searchHighlightDescription}
            searchWords={[searchQuery]}
            autoEscape
            textToHighlight={option.description}
          />
        </div>
      )}
    </Root>
  );
};

export default PatientSearchResult;
