import { format } from 'libphonenumber-js';
import moment from 'moment';
import uniqid from 'uniqid';

import { PatientAutocompleteOption, PatientElasticsearchResult } from './NavBar.types';

const onelifeUrl = process.env.REACT_APP_ONELIFE_URL;
const chartUrl = process.env.REACT_APP_CLINICAL_UX_URL;
const maxResults = 7;
export const viewAllResults = 'View All Results';

const linkToPatient = ({ isAdmin, patientId }: { isAdmin: boolean; patientId: number }) => {
  return isAdmin
    ? `${onelifeUrl}/${patientId}/account`
    : `${chartUrl}/#/patients/${patientId}/chart/summaries/new`;
};

// eslint-disable-next-line import/prefer-default-export
export const mapElasticSearchResultsToOptions = ({
  searchResults,
  searchQuery,
  isAdmin,
  totalSearchResults,
}: {
  searchResults: PatientElasticsearchResult[];
  searchQuery: string;
  isAdmin: boolean;
  totalSearchResults: number;
}) => {
  const allResultsLink = `${onelifeUrl}/admin/search?utf8=✓&name=${searchQuery}&service_area_id=all`;
  const patientResults: PatientAutocompleteOption[] = searchResults.map((result) => {
    const { nickname, first_name, phone, email, id, display_dob, last_name, dob } = result;
    const formatted_age = `${moment().diff(moment(dob), 'years')} yo`;
    const displayFirstName = nickname ? `${nickname} (${first_name})` : first_name;
    const contactInfo = phone.includes(searchQuery.replace(/-|\(|\)|\s/g, ''))
      ? format(phone[0], 'US', 'National')
      : email && email.join(', ');

    return {
      key: uniqid(),
      id,
      display_dob,
      title: `${displayFirstName} ${last_name}`,
      description: `${display_dob} (${formatted_age}) · ${contactInfo}`,
      link: linkToPatient({ isAdmin, patientId: id }),
    };
  });

  return totalSearchResults > maxResults
    ? patientResults.concat([
        {
          key: uniqid(),
          title: viewAllResults,
          link: allResultsLink,
        },
      ])
    : patientResults;
};
