const roles = Object.freeze({
  PROVIDER: 1,
  ADMIN: 2,
  CONTACT_ADMIN: 6,
  VMT: 12,
  MANAGER: 18,
  PCP: 21,
  BILLING_ADMIN: 23,
  UCP: 30,
  RN: 37,
  PEDIATRICS: 43,
  ADMIN_TRAINEE: 67,
  MEDICAL_RECORDS_ADMIN: 68,
  AUTHS_ADMIN: 69,
  FRONT_DESK_ADMIN: 70,
  VMT_FOCUS: 71,
  VMT_AFTER_HOURS: 74,
  MASS_CANCELLATION: 83,
  HELP_OUT_MODE: 87,
});

export default roles;
