import { IconButton, styled, SvgIcon } from '@mui/material';

const oneLifeUrl = process.env.REACT_APP_ONELIFE_URL;

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  height: '40px',
  width: '40px',
}));

const StyledSvgIcon = styled(SvgIcon)(() => ({
  fill: 'white',
  height: '20px',
  width: '20px',
}));

const Logo = () => (
  <StyledIconButton
    aria-label="home"
    onClick={() => window.open(`${oneLifeUrl}/admin`)}
    color="inherit"
    size="large"
  >
    <StyledSvgIcon viewBox="0 0 92.1 92.1">
      <title>Home</title>
      <path d="M11.5,34.6A11.42,11.42,0,0,0,0,46,11.65,11.65,0,0,0,11.5,57.6,11.59,11.59,0,0,0,23.1,46.1h0A11.59,11.59,0,0,0,11.5,34.6Zm34.6,0A11.5,11.5,0,1,0,57.6,46.1h0A11.5,11.5,0,0,0,46.1,34.6Zm0,34.5A11.5,11.5,0,1,0,57.6,80.6h0A11.57,11.57,0,0,0,46.1,69.1ZM46.1,0A11.5,11.5,0,1,0,57.6,11.5h0A11.57,11.57,0,0,0,46.1,0ZM80.6,34.6A11.5,11.5,0,1,0,92.1,46.1,11.5,11.5,0,0,0,80.6,34.6Z" />
    </StyledSvgIcon>
  </StyledIconButton>
);

export default Logo;
