import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Alert, CircularProgress, Grid, styled } from '@mui/material';
import { OAuthCallbackRoute, PrivateRoute, useAuthentication } from '@onemedical/auth';
import MicroFrontend from '@onemedical/micro-frontend';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';

import PendoHelper from '../helpers/pendo';
import mixpanel from '../mixpanel';
import NavBar from './NavBar';

const GET_PROFILE = gql`
  query GetProfile {
    profile {
      id
      roles {
        id
      }
      ...PendoHelperProfile
      ...NavBarProfile
    }
  }
  ${PendoHelper.fragments.profile}
  ${NavBar.fragments.profile}
`;

const hostedApps = [
  {
    id: 'admin',
    path: '/admin',
    host: process.env.REACT_APP_ADMIN_ASSETS_URL!,
  },
  {
    id: 'schedule',
    path: '/schedule',
    host: process.env.REACT_APP_SCHEDULE_ASSETS_URL!,
  },
  {
    id: 'panel-management',
    path: '/panel-management',
    host: process.env.REACT_APP_PANEL_MANAGEMENT_ASSETS_URL!,
  },
  {
    id: 'reconciliations',
    path: '/reconciliations',
    host: process.env.REACT_APP_RECONCILIATIONS_ASSETS_URL!,
  },
  {
    id: 'members',
    path: '/members',
    host: process.env.REACT_APP_MEMBER_MANAGEMENT_ASSETS_URL!,
  },
];

const StyledGrid = styled(Grid)(() => ({
  height: '100vh',
  width: '100%',
}));

function App() {
  const [, { authenticating, authenticated, error: authError }] = useAuthentication();
  const ldClient = useLDClient();
  const { data, loading } = useQuery(GET_PROFILE, {
    skip: !authenticated,
    onCompleted: (profileData) => {
      if (profileData) {
        const { profile } = profileData;
        const pendo = new PendoHelper();
        pendo.initialize(profile);
        mixpanel.identify(profile.id);
      }
    },
  });

  useEffect(() => {
    if (data && data.profile && ldClient) {
      ldClient.identify({ key: data.profile.id });
    }
  }, [data, ldClient]);

  const showLoadingScreen = authenticating || authError || loading;

  if (showLoadingScreen) {
    return (
      <StyledGrid container>
        <Grid item container justifyContent="center" alignItems="center" direction="column">
          {authError ? (
            <Grid item>
              <Alert severity="error">{authError}</Alert>
            </Grid>
          ) : (
            <>
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <div>{authenticating && 'Checking credentials...'}</div>
              </Grid>
            </>
          )}
        </Grid>
      </StyledGrid>
    );
  }

  return (
    <BrowserRouter>
      <OAuthCallbackRoute />
      {/* legacy routes used by onelife (to be deleted) */}
      <Route
        path={['/contacts', '/contact', '/provider-directory']}
        render={({ location }) => <Redirect to={`/admin${location.pathname}${location.search}`} />}
      />
      <Route
        path={['/appointments', '/phlebotomy-visits', '/provider-schedule']}
        render={({ location }) => (
          <Redirect to={`/schedule${location.pathname}${location.search}`} />
        )}
      />
      {/* end of legacy routes */}
      <Route exact path="/">
        {authenticated && <Redirect to="/admin" />}
      </Route>
      <PrivateRoute path="/">
        <NavBar profile={data && data.profile} />
      </PrivateRoute>
      {hostedApps.map(({ id, path, host }) => (
        <Route
          key={id}
          path={path}
          render={({ history }) => (
            <MicroFrontend
              id={id}
              host={host}
              history={history}
              containedAppProps={data} // used to trigger a refresh on profile change
              onError={(error) => {
                console.error(`Failed to load '${id}' micro-frontend`, error); // eslint-disable-line no-console
              }}
            >
              <StyledGrid container justifyContent="center" alignItems="center" direction="column">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </StyledGrid>
            </MicroFrontend>
          )}
        />
      ))}
    </BrowserRouter>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID!,
  options: {
    sendEventsOnlyForVariation: true,
  },
  flags: {
    'provider-appt-inventory-ui': false,
    'workflow-help-out-mode': false,
    'docs-ui-upgrade': false,
  },
})(App);
